:host {
	display: block;
}

.root {
	--tds-toaster-offset-top: 4rem;
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: var(--tds-color-sand);
}
.notification {
}
.banner {
	height: 16rem;
	background-position: center center;
	background-size: cover;
}
.container {
	box-sizing: border-box;
	position: relative;
	top: -4rem;
	padding-left: var(--tds-spacing-6);
	padding-right: var(--tds-spacing-6);
}
.desktop {
	margin-left: auto;
	margin-right: auto;
	max-width: var(--tds-container-xl);
	flex-wrap: wrap;
	align-items: flex-start;
	display: flex;
}
.left {
	width: 50%;
}
.right {
	padding-left: var(--tds-spacing-6);
	width: 50%;
	box-sizing: border-box;
}
@media (min-width: 68rem) {
	.left {
		width: 66.66667%;
	}
	.right {
		width: 33.33333%;
	}
}
