#settings *,
#settings *:before,
#settings *:after {
	box-sizing: border-box;
}

#settings-page .nav {
	top: 1.3125rem;
	/* top: 4.75rem; */
	width: 100%;
}

#settings-page .grid {
	align-items: unset;
}

#settings-page .nav-col {
	display: none;
}

#settings-page .layout__container {
	min-height: calc(100vh - 136px);
}

@media (min-width: 64em) {
	#settings-page .nav-col {
		display: block;
	}
}
#settings-page .settings__tooltip {
	background-color: var(--lwc-colorBackgroundAltInverse, rgb(3, 45, 96));
	color: var(--lwc-colorTextInverse, rgb(255, 255, 255));
}

/* .cSettingsLogins .action-item {
	border: 1px solid var(--c-colorFog, rgb(226, 226, 226));
	border-radius: 0.5rem;
}
.cSettingsLogins .cDisabledMessageButton {
	vertical-align: middle;
} */
