#profile-card .heading {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding-right: var(--tds-spacing-12);
}

#profile-card .details {
	display: block;
	transition: transform 0.2s linear;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

#profile-card .col {
	flex: 1 1 auto;
}

#profile-card .slds-card {
	box-sizing: border-box;
	border: 1px solid transparent;
	box-shadow: 0 2px 0px -1px rgb(0 0 0 / 10%);
	margin-top: var(--tds-spacing-6);
	margin-bottom: var(--tds-spacing-6);
}

#profile-card .name {
	font-size: var(--tds-font-size-3xl);
	margin: var(--tds-spacing-2) 0 0 0;
}

#profile-card .company {
	font-size: var(--tds-font-size-xl);
	margin-bottom: var(--tds-spacing-1);
}

#profile-card .location {
	color: var(--text-color-secondary);
}

#profile-card .empty-state-link {
	color: var(--tds-color-meteorite);
	margin-top: var(--tds-spacing-3);
	text-decoration: underline;
	font-style: italic;
}

#profile-card h1 {
	display: block;
	margin-block-start: 0.67em;
	margin-block-end: 0.67em;
	margin-inline-start: 0.67em;
	margin-inline-end: 0.67em;
	font-weight: bold;
}

#profile-card .avatar {
	border-radius: 50%;
	margin-top: -3rem;
	margin-right: var(--tds-spacing-4);
	background-color: var(--tds-color-white);
	background-position: center center;
	background-size: cover;
	border: 6px solid var(--tds-color-white);
	height: 11.25rem;
	width: 11.25rem;
	position: relative;
	top: -6px;
}

#profile-card .avatar-img {
	background-color: var(--c-colorWhite, rgb(255, 255, 255));
	background-position: center center;
	background-size: cover;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 180 181' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='90' cy='90' fill='%23f5f5f5' r='90'/%3e%3cpath d='M119.7 144.7c2.1-.8 3.2-1.4 3.2-1.4 38.1-24.1 31.9-54.8 13.7-79.1 8.1-45.7-21.7-21.9-27.8-16.6-17.3-4-34.4-1.3-34.4-1.3-1.5 0-2.9.2-4.3.3-5.8-5.4-34.9-30.4-28.4 15.4-1 1.2-1.6 2-1.6 2-34.3 49.3 14.6 77.8 14.6 77.8.9.4 1.9.8 2.8 1.2-4.5.5-9.9 2.2-14.2 5-2.8 1.8-6.9 4.2-10.4 12 15.5 12.7 35.4 20.4 57 20.4 20 0 38.5-6.5 53.5-17.6-5.9-12.3-17.1-16.3-23.7-18.1z' fill='%23e9e9e9'/%3e%3cpath d='M46.1 62c-3.4-4.3-2.9-20 3.1-22 1.7-.1 3.3.4 4.7 1.4 0 0 13 11.3 6.2 18-5.9 5.8-11.8 5.4-14 2.6zM36.7 76S15.8 114.4 54 136c0 0-30.2-26-17.3-60zM134.9 47.1c-.4-1.1-.9-2.2-1.6-3.1-.6-.9-1.5-1.5-2.4-1.9h-.8c-.7 0-1.4.1-2 .2-.9.2-1.7.6-2.4 1.2-1.5 1.3-3 2.8-4.3 4.3-1.1 1.3-2.1 2.8-3 4.3s-1.4 3.2-1.5 5c0 1.9.7 3.7 2 5 3.3 3.5 6.7 5 9.5 5 1.9.1 3.7-.6 5-2 .9-1.2 1.5-2.6 1.8-4.1.4-1.9.7-3.8.7-5.7.2-2.9-.2-5.6-1-8.2z' fill='%23dedede'/%3e%3cpath d='M134 103.6c.4 16.1-12 30.1-29.6 35.3-4.4 1.3-8.9 2-13.5 2.1-18.9.5-35.2-8.8-41.8-22.4-.2-.4-.4-.8-.5-1.2-1.6-3.6-2.5-7.5-2.6-11.5-.5-20 19.6-37.3 43.9-37.9 4.4-.1 8.7.3 12.9 1.3 17.9 4.2 30.7 18 31.2 34.3z' fill='%23f8f8f8'/%3e%3cg fill='%23dbdbdb'%3e%3cellipse cx='68.5' cy='115' rx='6.5' ry='4' transform='rotate(-85.89 68.5 115) scale(.99997)'/%3e%3cellipse cx='108.5' cy='118' rx='6.5' ry='4' transform='rotate(-85.89 108.5 118) scale(.99997)'/%3e%3cpath d='M52.8 124s-4-25.6 2.4-26.3c0 0 3.3 4.2 5.5 4.2.4 0-1.5-13 5.4-17.1 0 0 24.8 9.6 27.6 3.9-.9-.5-10-3.9-9.6-7.9.2-2.2 19.5-2.1 28 16.7 1.4.8 3.6-2.8 4.1-8 .9-9.7 7.9 16.3 8.1 16.7.1.4 2-.6 3.7-3 1.6-2.4 1.1 15.6.8 17.5-.3 1.8 6.3-7.7 5.2-19.9s-11.9-25-21.3-28.8c-8.9-3.6-24-10.2-47.1 1.8-24.7 12.8-18.9 39-18.8 39.4.6 3.1 5 10.6 6 10.8zM89.5 180.5c5.2 0 10.4-.5 15.4-1.3-.6-9.1-.7-24.2-17.4-24.2-17.9 0-17.7 13.7-18.3 23.2 6.6 1.5 13.3 2.3 20.3 2.3z'/%3e%3c/g%3e%3c/svg%3e");
	border: 6px solid var(--c-colorWhite, rgb(255, 255, 255));
	border-radius: 50%;
	height: 11.25rem;
	width: 11.25rem;
	transition: border-color 0.2s ease;
}

#profile-card .avatar-img_expandable:hover {
	border-color: var(--c-colorFog, rgb(226, 226, 226));
}

.cAvatarUploader {
	right: 16px;
	bottom: 10px;
}

.cAvatarUploader > .slds-button {
	border-radius: 50%;
}

.cAvatarUploader .picture {
	width: 220px !important;
	height: 220px !important;
}

.cAvatarUploader .removePhotoButton {
	width: 100%;
	margin: 0.5rem 0;
}

.cAvatarUploader .croppie-container .cr-slider-wrap {
	margin: 5px auto;
}

.cAvatarUploader img {
	max-width: 220px;
	max-height: 220px;
}

.cAvatarUploader .form-footer {
	height: 35px;
}

#profile-card .bio {
	margin-top: var(--tds-spacing-4);
	margin-bottom: 0;
}

#profile-card .edit {
	position: absolute;
	top: var(--tds-spacing-6);
	right: var(--tds-spacing-6);
}

#profile-card .footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

#profile-card {
	box-sizing: unset;
}

#profile-card footer .social-links {
	margin-top: var(--tds-spacing-6);
}

#profile-card .heading .social-links {
	margin-top: var(--tds-spacing-2);
}

#profile-card .slug {
	border-radius: var(--tds-radius-md);
	border: 1px solid var(--tds-color-fog);
	max-width: 23rem;
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: var(--tds-spacing-6);
}

#profile-card .slug-icon {
	width: auto;
	height: var(--tds-spacing-3);
	margin-right: var(--tds-spacing-3);
	margin-left: var(--tds-spacing-3);
	flex-shrink: 1;
	margin-top: 1px;
}

#profile-card .slug-value {
	position: absolute;
	margin: -1px;
	border: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	text-transform: none;
	white-space: nowrap;
}

#profile-card .slug-url {
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-color);
	text-decoration: none;
	flex-grow: 1;
}

#profile-card .slug-url:hover,
#profile-card .slug-url:focus {
	text-decoration: underline;
	color: #005fb2;
}

#profile-card .foot {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

#profile-card .card__body_inner {
	padding: 0 var(--tds-spacing-6) var(--tds-spacing-6);
}

#profile-card .card__action {
	margin-right: calc(-1 * var(--tds-spacing-1));
	margin-top: calc(-1 * var(--tds-spacing-1));
	flex: none;
}

#profile-card header {
	padding: var(--tds-spacing-6) var(--tds-spacing-6) var(--tds-spacing-6);
	margin: 0 0 var(--tds-spacing-3);
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

#profile-card > div > div.heading > div.avatar > div > div > div > button > svg {
	left: 23%;
}

#profile-card > div > div.edit > button > svg {
	left: 20%;
}

#profile-card > div > div.footer > div > button > svg,
#profile-card > div > div.heading > div.avatar > div > div > div > button > svg,
#profile-card > div > div.edit > button > svg {
	width: var(--tds-icon-size, var(--tds-spacing-5));
	height: var(--tds-icon-size, var(--tds-spacing-5));
}
